<script setup lang="ts">
import { PopoverButton } from "@headlessui/vue";
interface Props {
  item: {
    name: string;
    href: string;
    icon: string;
    action?: () => void;
  };
}

defineProps<Props>();
const route = useRoute();
</script>

<template>
  <div>
    <PopoverButton v-if="item.action" @click="item.action">
      <div
        class="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
        :class="{ 'bg-gray-50': route.path === item.href }"
      >
        <div
          class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
          :class="{ 'bg-white': route.path === item.href }"
        >
          <Icon
            :name="item.icon"
            class="h-6 w-6 group-hover:text-primary"
            :class="{ 'text-primary': route.path === item.href }"
            aria-hidden="true"
          />
        </div>
        <div class="flex-auto font-semibold">
          {{ item.name }}
        </div>
      </div>
    </PopoverButton>
    <NuxtLink v-else :to="item.href">
      <div
        class="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
        :class="{ 'bg-gray-50': route.path === item.href }"
      >
        <div
          class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
          :class="{ 'bg-white': route.path === item.href }"
        >
          <Icon
            :name="item.icon"
            class="h-6 w-6 group-hover:text-primary"
            :class="{ 'text-primary': route.path === item.href }"
            aria-hidden="true"
          />
        </div>
        <div class="flex-auto font-semibold">
          <PopoverButton>{{ item.name }}</PopoverButton>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>
